import "core-js/modules/es.number.constructor.js";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "grape-inner"
};
export default {
  __name: 'BaseGrape',
  props: {
    gap: {
      type: [String, Number]
    },
    // gap是否可以影響外部
    gapAffectOutside: {
      type: Boolean,
      default: true
    },
    inline: {
      type: Boolean,
      default: false
    },
    // 單行靠右
    singleLineRight: {
      type: Boolean,
      default: false
    },
    // 水平置中
    hCenter: Boolean,
    alignEnd: Boolean,
    spaceBetween: Boolean
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["base-grape", [{
          'base-grape_single-line-align-right': __props.singleLineRight
        }, {
          'base-grape_align-end': __props.alignEnd
        }, {
          'base-grape_h-center': __props.hCenter
        }, {
          'base-grape_gap-affect-outside': __props.gapAffectOutside
        }, {
          'base-grape_space-between': __props.spaceBetween
        }]]),
        style: _normalizeStyle([__props.inline ? "--grape-inner-display:inline-flex" : '', __props.gap ? "--base-grape-gap:".concat(__props.gap) : ''])
      }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default")])], 6);
    };
  }
};